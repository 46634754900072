import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { FaTelegram } from 'react-icons/fa';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Contact.css';

// Создаем кастомную иконку для маркера
const customIcon = new L.Icon({
    iconUrl: '/img/balaclava.png', // Укажите путь к вашей иконке
    iconSize: [35, 45],
    iconAnchor: [17, 45],
    popupAnchor: [0, -45],
    className: 'custom-icon'
});

const Contact = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Плавное отображение после загрузки компонента
        setIsLoaded(true);
    }, []);

    return (
        <div className={`contact-container ${isLoaded ? 'loaded' : ''}`}>
            <h1 className="contact-title">Контакты</h1>

            <div className="map-container">
                <MapContainer
                    center={[56.132, 160.5]} // Координаты Камчатки
                    zoom={5}
                    scrollWheelZoom={false}
                    style={{ height: '400px', width: '100%' }}
                    attributionControl={false} // Отключение атрибуции
                >
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    />
                    <Marker position={[56.132, 160.5]} icon={customIcon}>
                        {/* <Popup>Камчатка</Popup> */}
                    </Marker>
                </MapContainer>
            </div>

            <h2 className="contact-subtitle">Наши Telegram-каналы</h2>
            <ul className="contact-links">
                <li>
                    <a href="https://t.me/RAPVECHER1NKA" target="_blank" rel="noopener noreferrer">
                        <FaTelegram className="telegram-icon" /> Основной канал
                    </a>
                </li>
                <li>
                    <a href="https://t.me/RapVecher1nkaLive" target="_blank" rel="noopener noreferrer">
                        <FaTelegram className="telegram-icon" /> Канал Радио Рэп Вечеринка Лайв
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Contact;