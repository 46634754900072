// ListenersMap.js
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import './ListenersMap.css';

// Создаем кастомную иконку для маркера (балаклавы)
const customIcon = new L.Icon({
    iconUrl: '/img/balaclava.png', // Укажите путь к вашей иконке балаклавы
    iconSize: [35, 45],
    iconAnchor: [17, 45],
    className: 'custom-icon'
});

// Координаты некоторых населенных пунктов на Камчатке
const LAND_COORDINATES = [
    [53.045, 158.65], // Петропавловск-Камчатский
];

const ListenersMap = () => {
    const [listeners, setListeners] = useState(0);
    const [markerPositions, setMarkerPositions] = useState([]);

    // Функция для получения данных о слушателях
    const fetchListenerCount = async () => {
        try {
            const response = await axios.get('https://stat.rapvecherinka.ru/status-json.xsl');
            const listenerCount = response.data.icestats.source.listeners;
            setListeners(listenerCount);
        } catch (error) {
            console.error("Ошибка при получении количества слушателей:", error);
        }
    };

    // Функция для генерации случайных координат на суше
    const generateLandCoordinates = (count) => {
        const positions = [];
        for (let i = 0; i < count; i++) {
            const randomIndex = Math.floor(Math.random() * LAND_COORDINATES.length);
            positions.push(LAND_COORDINATES[randomIndex]);
        }
        return positions;
    };

    useEffect(() => {
        // Обновляем количество слушателей каждые 30 секунд
        fetchListenerCount();
        const interval = setInterval(fetchListenerCount, 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // Обновляем позиции маркеров при изменении количества слушателей
        const positions = generateLandCoordinates(listeners);
        setMarkerPositions(positions);
    }, [listeners]);

    return (
        <div className="listeners-map-container">
            <MapContainer center={[53.045, 158.65]} zoom={12} style={{ height: '400px', width: '100%' }} attributionControl={false}>
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                />
                {markerPositions.map((position, index) => (
                    <Marker key={index} position={position} icon={customIcon} />
                ))}
            </MapContainer>
        </div>
    );
};

export default ListenersMap;