import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { FaPlayCircle, FaPauseCircle, FaVolumeUp, FaUsers } from 'react-icons/fa';
import './Player.css';

const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};

const Player = () => {
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // Начальный уровень громкости
    const [listeners, setListeners] = useState(0); // Количество слушателей
    const listenUrl = 'https://listen.rapvecherinka.ru'; // URL для прослушивания

    useEffect(() => {
        const apiUrl = 'https://stat.rapvecherinka.ru/status-json.xsl'; // API URL

        const fetchData = () => {
            fetch(apiUrl)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Ошибка HTTP: ${response.status}`);
                    }
                    return response.json(); // Парсинг JSON
                })
                .then(data => {
                    const sourceData = data.icestats.source;
                    setCurrentTrack(sourceData);
                    setListeners(sourceData.listeners); // Количество слушателей теперь в source
                })
                .catch(error => {
                    console.error('Ошибка при загрузке данных:', error);
                });
        };

        fetchData();

        const interval = setInterval(fetchData, 30000);

        return () => clearInterval(interval);
    }, []);

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    const handleVolumeChange = (e) => {
        setVolume(parseFloat(e.target.value));
    };

    return (
        <div className="player-container">
            {currentTrack ? (
                <div className="player-content">
                    <div className="left-panel">
                    <div className="vinyl-record">
                        <img src="/img/plastinka.png" alt="Vinyl Record" className="vinyl-image" loading="lazy" />
                    </div>
                        <h1 className="station-name">Radio RapVecherinka</h1>
                        <p className="listeners">
                            <FaUsers className="listeners-icon" />
                            Слушателей сейчас: {listeners}
                        </p>
                    </div>

                    <div className="right-panel">
                        <h2 className="current-track">
                            <span className="track-name">{decodeHtml(currentTrack.title)}</span>
                        </h2>

                        <div className="audio-controls">
                            {isPlaying ? (
                                <FaPauseCircle 
                                    onClick={togglePlay} 
                                    className="play-pause-btn" 
                                />
                            ) : (
                                <FaPlayCircle 
                                    onClick={togglePlay} 
                                    className="play-pause-btn" 
                                />
                            )}
                            <div className="volume-control">
                                <FaVolumeUp className="volume-icon" />
                                <input 
                                    type="range" 
                                    min="0" 
                                    max="1" 
                                    step="0.01" 
                                    value={volume} 
                                    onChange={handleVolumeChange} 
                                    className="volume-slider"
                                />
                            </div>
                        </div>
                        <ReactPlayer
                            url={listenUrl} // Используем URL потока
                            playing={isPlaying}
                            volume={volume}
                            width="0" // Скрываем интерфейс плеера
                            height="0"
                            onError={(e) => console.error('Ошибка воспроизведения:', e)} // Логирование ошибок
                        />
                    </div>
                </div>
            ) : (
                <p>Загрузка...</p>
            )}
        </div>
    );
};

export default Player;