import React, { useEffect, useState } from 'react';
import './About.css';

const About = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div className={`about-container ${isLoaded ? 'loaded' : ''}`}>
            <div className="about-logo">
                <img src="/img/logo.png" alt="Radio Logo" className="logo-image" />
            </div>
            <h1 className="about-title">О Radio RapVecherinka</h1>
            <p className="about-description">
                Radio RapVecherinka — это уникальная интернет-радиостанция, созданная для настоящих любителей рэп-музыки и хип-хопа. 
                Мы предлагаем разнообразные треки, начиная от классики до современных хитов, и предоставляем нашим слушателям 
                отличную атмосферу и непрерывный поток качественной музыки.
            </p>
            <h2 className="about-rules-title">Правила использования</h2>
            <ul className="about-rules">
                <li>Radio RapVecherinka — развлекательный ресурс, предназначенный для ознакомления с музыкальными композициями.</li>
                <li>Все музыкальные произведения, которые транслируются, предоставляются исключительно в ознакомительных целях.</li>
                <li>Слушая радио, вы соглашаетесь с тем, что прослушиваете контент для личного ознакомления и в рамках действующего законодательства.</li>
                <li>Наш сайт не занимается распространением пиратского контента. Мы ценим права авторов и соблюдаем все нормы закона.</li>
            </ul>
            <p className="about-note">
                Пожалуйста, помните, что наш сайт носит исключительно информационный характер и не является пиратским ресурсом.
                Музыка, которую вы слушаете, предназначена для ознакомления и временного использования на вашем устройстве.
            </p>
        </div>
    );
};

export default About;