import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link для маршрутизации
import { FaBars, FaTimes } from 'react-icons/fa';
import './NavBar.css';

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">Radio RapVecherinka</div>
            <div className="burger-icon" onClick={toggleMenu}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
                <li>
                    <Link to="/" onClick={toggleMenu}>Главная</Link>
                </li>
                <li>
                    <Link to="/about" onClick={toggleMenu}>О радио</Link>
                </li>
                <li>
                    <Link to="/contact" onClick={toggleMenu}>Контакты</Link>
                </li>
            </ul>
        </nav>
    );
};

export default NavBar;