import React from 'react';
import { motion } from 'framer-motion';
import './LoadingScreen.css';

const LoadingScreen = ({ setLoading }) => {
    return (
        <motion.div
            className="loading-screen"
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration:5.5 }}
            onAnimationComplete={() => setLoading(false)} // скрываем компонент после завершения анимации
        >
            <motion.div
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1 }}
                className="loading-logo"
            >
                <img src="/img/logo.png" alt="Radio Logo" className="logo-image" />
            </motion.div>
        </motion.div>
    );
};

export default LoadingScreen;