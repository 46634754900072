// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import Player from './components/Player';
import NavBar from './components/NavBar';
import About from './components/About';
import Contact from './components/Contact';
import ListenersMap from './components/ListenersMap'; // Импортируем компонент карты
import './App.css';

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 6000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="App">
            {loading ? (
                <LoadingScreen setLoading={setLoading} />
            ) : (
                <Router>
                    <NavBar />
                    <Routes>
                        <Route path="/" element={
                            <>
                                <Player />
                                <ListenersMap /> {/* Добавляем карту с динамическими балаклавами */}
                            </>
                        } />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </Router>
            )}
        </div>
    );
}

export default App;